@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
* {
  margin: 0px;
  padding: 0px;
  border: 0;
  outline: 0;
  background-color: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  box-shadow: none;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4 {
  line-height: 1.2;
  color: var(--clr-fg-alt);
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.3rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

body{
  background-color: var(--clr-bg);
  color: var(--clr-fg);
}

.nav__list {
  margin-right: 1.5em;
  display: flex;
}
.nav__list-item {
  margin-left: 1.5em;
}
.app .nav__hamburger {
  display: none;
}
.nav__theme {
  margin-top: 0.4em;
}

@media (max-width: 600px) {
  .nav__list {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .nav__list-item {
    margin: 0.5em 0;
  }

  .app .nav__hamburger {
    display: flex;
    z-index: 2;
    margin-left: 0.8em;
  }
}

.header {
  height: 8em;
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .header {
    height: 6em;
  }
}

img{
  width: 30px;
  margin-right: 4px;
}

.app {
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: var(--clr-fg);
  background-color: var(--clr-bg);
}

.light {
  --clr-bg: #fcfcfc;
  --clr-bg-alt: #fff;
  --clr-fg: #555;
  --clr-fg-alt: #444;
  --clr-primary: #fe7f01;
  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dark {
  --clr-bg: #23283e;
  --clr-bg-alt: #2a2f4c;
  --clr-fg: #bdbddd;
  --clr-fg-alt: #cdcdff;
  --clr-primary: #90a0d9;
  --shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

main {
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
}

.section {
  margin-top: 5em;
}

.section__title {
  text-align: center;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.center {
  display: flex;
  align-items: center;
}

.link {
  color: var(--clr-primary);
  padding: 0 0 0.3em 0;
  position: relative;
}

.link:hover {
  color: var(--clr-primary);
}

.link::before {
  content: "";
  display: inline;
  width: 0%;
  height: 0.2em;
  position: absolute;
  bottom: 0;
  background-color: var(--clr-primary);
  transition: width 0.2s ease-in;
}

.link:hover::before,
.link:focus::before {
  width: 100%;
}

.link--nav {
  color: var(--clr-fg);
  font-weight: 500;
}

.link--icon {
  color: var(--clr-fg);
}

.btn {
  display: block;
  cursor: pointer;
  padding: 0.8em 1.4em;
  font-weight: 500;
  font-size: 0.9rem;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.btn--outline {
  color: var(--clr-primary);
  border: 2px solid var(--clr-primary);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn--outline:hover,
.btn--outline:focus {
  color: var(--clr-bg);
}

.btn--outline:before {
  content: "";
  position: absolute;
  background-color: var(--clr-primary);
  right: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: -1;
  transition: right 0.2s ease-in-out;
}

.btn--outline:hover:before,
.btn--outline:focus:before {
  right: 0;
}

.btn--plain {
  text-transform: initial;
  background-color: var(--clr-bg-alt);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  border: 0;
}

.btn--plain:hover {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.btn--icon {
  padding: 0;
}

.btn--icon:hover,
.btn--icon:focus {
  color: var(--clr-primary);
}

.btn--icon:active {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

@media (max-width: 600px) {
  .section {
    margin-top: 4em;
  }
}

.about {
  flex-direction: column;
  margin-top: 3em;
}
.about__name {
  color: var(--clr-primary);
}
.about > :nth-child(2) {
  margin-top: 1.2em;
  font-size: 2rem;
  line-height: 1.2;
  color: var(--clr-fg-alt);
}

.about__desc {
  font-size: 1rem;
  max-width: 70%;
  text-align: center;
}

.about__desc,
.about__contact {
  margin-top: 2.4em;
}

.about .link--icon {
  margin-right: 0.8em;
}

.about .btn--outline {
  margin-right: 1em;
}
.btnResume{
  color: var(--clr-primary);
  border: 2px solid var(--clr-primary);
  border-radius: 5px;
  height: 50px;
  cursor: pointer;
  padding: 0px 30px;
  margin-top: 10px;
}
.btnResume:hover{
  background-color: var(--clr-primary);
  color: var(--clr-bg);
}

@media (max-width: 600px) {
  .mobileHead{
    align-content: center;
  }
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
    justify-content: center;
    align-items: center;
  }
  .about > :nth-child(2) {
    font-size: 1.5rem;
  }
}

.introduction {
  width: 100%;
  display: flex;
  margin-top: 4em;
  margin-bottom: 4em;
}

.different {
  color: var(--clr-primary);
}

.introduction_logocontainer {
  flex-basis: 45%;
  display: flex;
  justify-content: center;
}

.introduction_logocontainer > img {
  width: 55%;
  height: 85%;
  border-radius: 50%;
  border: 2px solid var(--clr-primary);
}

.introduction_datacontainer {
  flex-basis: 55%;
}

.introduction_datacontainer > h4:nth-child(1) {
  margin-top: 0;
  line-height: 1.4em;
}

.introduction_datacontainer > h4:nth-child(2) {
  margin-top: 1.4em;
  line-height: 1.4em;
}

.introduction_datacontainer > h4 {
  font-size: 1.1em;
  margin-top: 0.5em;
}

.icons {
  position: relative;
  top: 5px;
  color: var(--clr-fg-alt);
  margin-right: 0.5em;
}

@media (max-width: 1022px) {
  .introduction {
    flex-direction: column;
    margin-top: 2em;
  }
  .introduction_logocontainer {
    margin-bottom: 3em;
    flex-basis: 30%;
  }
  .introduction_logocontainer > img {
    width: 60%;
    height: 100%;
    border-radius: 20px;  
    border-radius: 50%;
    border: 2px solid var(--clr-primary);
  }
  .introduction_datacontainer {
    padding-left: 10%;
    padding-right: 10%;
    flex-basis: 70%;
  }
}

.mainsection {
  width: 100%;
}

.mainsection > :nth-child(1) {
  margin-bottom: 2em;
}

.vertical-timeline-element-subtitle {
  margin-top: 0.5em;
  font-size: 0.9em;
}

.vertical-timeline-element-title {
  font-size: 1.1em;
  color: var(--clr-primary);
}



.main {
  margin-top: 4em;
}
.techsection {
  width: 100%;
  text-align: center;
  background-color: var(--clr-bg);
  height: 50vh;
  /* max-height: 400px; */
}

.techsection > div {
  width: 11%;
  height: 16vh;
  max-height: 140px;
  box-shadow: var(--shadow);
  z-index: 1;
  border-radius: 20px;
  margin: 2.5%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease 0s;
  border: 2px solid var(--clr-primary);
}
.techsection > div:hover {
  background-color: var(--clr-bg);
  overflow: hidden;
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
  box-shadow: rgba(0, 0, 0, 0.36) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border: 1px solid var(--clr-primary);
}

.techsection > div > svg {
  /* height: 50%;
  width: 50%; */
  font-size: 8vh;
  color: var(--clr-primary);
}
.techsection > div > h5 {
  position: relative;
  top: 8px;
  background-color: transparent;
}

@media (max-width: 600px) {
  .techsection {
    height: 120vh;
  }
  .techsection > div {
    width: 40%;
    margin-right: 5%;
    margin-bottom: 2%;
    height: 18vh;
  }
}

.projects_container {
  width: 80%;
  margin: 0 auto;
  background-color: transparent;
}

.project {
  width: 100%;
  /* height: 70vh; */
  margin: 6% 0% 6% 0%;
  box-shadow: var(--shadow);
  display: flex;
  border-radius: 20px;
  transition: all 0.5s ease 0s;
}

.project:hover {
  -webkit-transform: scale(1.04);
          transform: scale(1.04);
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.36) 0px 10px 15px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.project_videocontainer {
  flex-basis: 65%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.project_videocontainer > div {
  width: 90%;
  height: 90%;
  
  border-radius: 20px;
}
.project_videocontainer > div > img {
  width: 100%;
  height: 100%;
  border: 3px solid var(--clr-primary);
  border-radius: 20px;
}

.project_information {
  flex-basis: 35%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0% 3% 4% 3%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 10px;
}
.project_information > h2 {
  margin-top: 1em;
  color: var(--clr-primary);
}
.project_information > p {
  margin-top: 1em;
  text-align: center;
}
.project_information > div:nth-child(3) {
  margin-top: 0.5em;
  display: flex;
  justify-content: space-evenly;
}

.project_information > div:nth-child(3) > svg {
  font-size: 3em;
  margin: 2% 3% 2% 3%;
  color: var(--clr-primary);
  transition: all 0.2s ease-in-out;
}

.project_information > div:nth-child(3) > svg:hover {
  font-size: 3.3em;
}

.project_information > div:nth-child(4) {
  margin-top: 0.6em;
  display: flex;
}
.onbt {
  margin: 0 1vw 0 1vw;
}
.btns{
  color: var(--clr-primary);
  border: 2px solid var(--clr-primary);
  border-radius: 5px;
  height: auto;
  cursor: pointer;
  padding: 10px;
  margin-top: 10px;
}
.btns:hover{
  color: var(--clr-bg);
  border: 2px solid var(--clr-primary);
  background-color: var(--clr-primary);
}
.project_information > h3 {
  margin-top: 1.6em;
  color: var(--clr-primary);
}
.project_information::-webkit-scrollbar {
  display: none;
}

.allProjects{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.project {
    border: 2px solid var(--clr-primary);
    flex-direction: column;
  }

@media (max-width: 800px) {
  .project {
    flex-direction: column;
    /* height: 100vh; */
  }
  .project_videocontainer {
    flex-basis: 40%;
  }
  .project_information {
    flex-basis: 60%;
  }
  .allProjects{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .projects_container {
    width: 100%;
    margin: 0 auto;
  }
}

.contactcontainer {
  display: flex;
  justify-content: space-between;
  padding-top: 2em;
  width: 450px;
  border-radius: 20px;
  background-color: transparent;
  margin: 10px;
  margin: auto;
  margin-bottom: 10px;
  
}

.contactcontainer svg {
  font-size: 3em;
  transition: all 0.5s ease-in-out 0s;
  color: var(--clr-primary);
  border: 3px solid var(--clr-primary);
  padding: 5px;
  border-radius: 20%;
}
.mailNumber .email {
  font-size: 2em;
  transition: all 0.5s ease-in-out 0s;
  color: var(--clr-primary);
}
.mailNumber .phone{
  font-size: 1.5em;
  transition: all 0.5s ease-in-out 0s;
  color: var(--clr-primary);
}

.mailNumber > div{
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-content: center; */
}

.mailNumber p{
  margin: 5px;
}
.contactcontainer svg:hover {
  font-size: 4rem;
}
.linkedin:hover {
  color: #0a66c2;
}
.github:hover {
  color: black;
}
.email:hover {
  color: #d93025;
}
.twitter:hover {
  color: rgb(29, 155, 240);
}
.phone:hover {
  color: rgb(0, 0, 0);
}

.mailNumber{
  border-radius: 20px;
  background-color: transparent;
  margin: auto;
  width: 500px;
  display: flex;
  justify-content: space-between;
}
/* .mailNumber:hover {
  font-size: 1.1rem;
  transform: scale(1.04);
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.36) 0px 10px 15px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
} */
.contactMain{
  width: 90%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: auto;
}
@media (max-width: 670px) {
  .contactcontainer svg {
    font-size: 2em;
  }
  .contactcontainer{
    width: 100%;
  }
  .mailNumber{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .mailNumber div{
    justify-content: center;
    align-content: center;
    text-align: center;
  }
  .contactMain{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px;
  }
  .iconsCont{
    width: 90%
  }
  .contactcontainer svg:hover {
    font-size: 3rem;
  }
}

.footer {
  text-align: center;
  color: var(--clr-primary);
}

.footerBox{
  /* border: 3px solid var(--clr-primary); */
  width: 50%;
  margin: auto;
  padding-bottom: 20px;
  border-radius: 15px;
  background-color: transparent;
  padding: 20px;
  height: auto;
}

/* .footerBox:hover {
  transform: scale(1.04);
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.36) 0px 10px 15px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
} */

@media (max-width: 600px) {
  .footerBox{
    /* border: 3px solid var(--clr-primary); */
    width: 100%;
    border-radius: 0px;
    margin-bottom: 0px;
  }
}
.scroll-top {
  position: fixed;
  bottom: 2em;
  right: 1em;
  background-color: transparent;
}

@media (max-width: 900px) {
  .scroll-top {
    display: none;
  }
}

